// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    'aws_project_region': 'eu-west-1',
    'aws_cognito_identity_pool_id': 'eu-west-1:c79de459-3d83-4872-be15-73c24cd6e481',
    'aws_cognito_region': 'eu-west-1',
    'aws_user_pools_id': 'eu-west-1_N3w11Bgi1',
    'aws_user_pools_web_client_id': '6k1pdsfl21bs9brq1l5k30tk2s',
    'oauth': {}
};


export default awsmobile;